// @flow
import { listForFontsLib } from './font-family-native';

export const presetsDesign = {
	custom_block: {
		_id: 'custom_block',
		kind: 'design',
		name: 'Custom',
		target: 'block',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'page',
		scopeId: '',
		theme: null,
		breakpoints: {
			desktop: {
				border: null,
				radius: null,
				normal: {
					container: {
						border: null,
						background: [
							{
								kind: 'color',
								value: 'bg.primary',
							},
						],
						shadow: null,
						overlay: null,
					},
					text: {
						color: null,
						shadow: null,
					},
				},
			},
		},
	},
	custom_image: {
		_id: 'custom_image',
		kind: 'design',
		name: 'Custom',
		target: 'image',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'page',
		scopeId: '',
		breakpoints: {},
	},
	custom_rectangle: {
		_id: 'custom_rectangle',
		kind: 'design',
		name: 'Custom',
		target: 'rectangle',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'page',
		scopeId: '',
		breakpoints: {
			desktop: {
				normal: {
					container: {
						background: [
							{
								value: '#E9ECEF',
								kind: 'color',
							},
						],
					},
					text: {
						color: 'text.oncolor',
					},
				},
			},
		},
	},
	custom_button: {
		_id: 'custom_button',
		kind: 'design',
		name: 'Custom',
		target: 'button',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'page',
		scopeId: '',
		breakpoints: {
			desktop: {
				height: 42,
				heightUnit: 'px',
				padding: 18,
				paddingUnit: 'px',
				radius: 'small',
				textDesign: 'body-button',
				textMargin: 0,
				textMarginUnit: 'px',
				btnIconSize: 16,
				btnIconSizeUnit: 'px',
				iconMargin: 6,
				iconMarginUnit: 'px',
				normal: {
					container: {
						border: 'bg.accentplus',
						background: [
							{
								value: 'bg.accent',
								kind: 'color',
							},
						],
						shadow: null,
						overlay: null,
					},
					text: {
						color: 'text.oncolor',
						shadow: null,
					},
				},
				border: 'thin',
				hover: {
					container: {
						border: 'bg.accentminus',
						background: [
							{
								value: 'bg.accentplus',
								kind: 'color',
							},
						],
						shadow: null,
						overlay: null,
					},
					text: {
						color: null,
						shadow: null,
					},
				},
			},
		},
	},
	custom_stack: {
		_id: 'custom_stack',
		target: 'stack',
		kind: 'design',
		userId: null,
		display: 'normal',
		protoId: null,
		removedAt: null,
		scope: 'page',
		scopeId: null,
		name: 'Custom',
		breakpoints: {
			desktop: {
				normal: {
					container: {
						border: null,
						background: [
							{
								value: 'bg.primaryalt',
								kind: 'color',
							},
						],
						shadow: null,
						overlay: null,
					},
					text: {
						color: null,
						shadow: null,
					},
				},
			},
		},
	},
	custom_input: {
		_id: 'custom_input',
		target: 'input',
		kind: 'design',
		userId: null,
		display: 'normal',
		protoId: null,
		removedAt: null,
		scope: 'page',
		scopeId: null,
		name: 'Custom',
		breakpoints: {
			desktop: {
				height: 42,
				heightUnit: 'px',
				padding: 16,
				paddingUnit: 'px',
				radius: 'medium',
				textDesign: 'body-text',
				textMargin: 0,
				textMarginUnit: 'px',
				border: 'thin',
				normal: {
					container: {
						border: 'bg.primaryalt',
						background: [],
						shadow: null,
						overlay: null,
					},
					text: {
						color: 'text.primary',
						shadow: null,
					},
					placeholder: {
						color: 'text.secondary',
						shadow: null,
					},
				},
			},
		},
	},
	custom_textarea: {
		_id: 'custom_textarea',
		target: 'textarea',
		kind: 'design',
		userId: null,
		display: 'normal',
		protoId: null,
		removedAt: null,
		scope: 'page',
		scopeId: null,
		name: 'Custom',
		breakpoints: {
			desktop: {
				padding: 16,
				paddingUnit: 'px',
				radius: 'medium',
				textDesign: 'body-text',
				textMargin: 0,
				textMarginUnit: 'px',
				border: 'thin',
				paddingSides: {
					top: 12,
					bottom: 12,
				},
				normal: {
					container: {
						border: 'bg.primaryalt',
						background: [],
						shadow: null,
						overlay: null,
					},
					text: {
						color: 'text.primary',
						shadow: null,
					},
					placeholder: {
						color: 'text.secondary',
						shadow: null,
					},
				},
			},
		},
	},
	custom_stackform: {
		_id: 'custom_stackform',
		target: 'stack',
		kind: 'design',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		scope: 'page',
		scopeId: '',
		name: 'Custom',
		breakpoints: {},
	},
	custom_text: {
		_id: 'custom_text',
		kind: 'design',
		name: 'Custom',
		target: 'text',
		section: 'body',
		userId: '',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'page',
		scopeId: '',
		breakpoints: {
			desktop: {
				family: listForFontsLib[0].family,
				variants: listForFontsLib[0].variants,
				weight: '400',
				fontSize: 16,
				textHeight: 120,
				textHeightUnit: '%',
				spacing: 0,
				textMargin: 0,
				textMarginUnit: 'px',
				lettercase: 'none',
				normal: {
					container: {
						background: null,
						border: null,
						overlay: null,
						shadow: null,
					},
					text: {
						color: 'text.primary',
						shadow: null,
					},
				},
			},
		},
	},
};

export default {};
