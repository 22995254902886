// @flow

import React from 'react';
import { zIndices } from '@graphite/constants';
import type { TSx } from '@graphite/types';
import Box from '../Box';

type TProps = $ReadOnly<{|
	style?: ?TSx,
	isActive: boolean,
|}>;

export const overlayOpenSx: TSx = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: zIndices.resizeOverlay,
	pointerEvents: 'auto',
};

export const overlayClosedSx: TSx = {
	display: 'none',
};

function Overlay({ style = null, isActive }: TProps): React$Node {
	const preventMenu = React.useCallback((e: SyntheticMouseEvent<EventTarget>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	return (
		<Box
			sx={isActive ? overlayOpenSx : overlayClosedSx}
			style={style}
			onContextMenu={preventMenu}
		/>
	);
}

export default React.memo<TProps>(Overlay);
