// @flow
import React from 'react';
import { zIndices } from '@graphite/constants';
import type { TSx } from '@graphite/types';
import Portal from '../Portal';
import Box from '../Box';

const DEFAULT_PADDING_X = 42;
const DEFAULT_SIZE_OVERLY = 30;

type TFakeMouseEvent = MouseEvent => void;

export type TProps = $ReadOnly<{|
	children: React$Node,
	topPosition?: number,
	onClose?: TFakeMouseEvent,
	sx?: ?TSx,
|}>;

const containerSx = {
	backgroundColor: 'rgba(0,0,0,0.5)',
	position: 'absolute',
	zIndex: zIndices.pageOverlay,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	padding: `${DEFAULT_SIZE_OVERLY}px`,
};

const boxContainerSx = {
	backgroundColor: 'spec.lightblue10',
	padding: `0 ${DEFAULT_PADDING_X}px`,
};

const Dialog = ({ children, onClose = null, topPosition = 0, sx = null }: TProps) => {
	const overlayBoxThemedSx = React.useMemo(
		() => ({
			...containerSx,
			...sx,
			...(topPosition ? { top: `${topPosition}px` } : {}),
		}),
		[sx, topPosition],
	);
	const mainContenerRef = React.useRef(null);
	const handleOnClose = React.useCallback(
		e => {
			if (mainContenerRef.current === e.target) onClose(e);
		},
		[onClose],
	);
	return (
		<Portal>
			<Box sx={overlayBoxThemedSx} onClick={handleOnClose} ref={mainContenerRef}>
				<Box sx={boxContainerSx}>{children}</Box>
			</Box>
		</Portal>
	);
};

export default React.memo<TProps>(Dialog);
