// @flow
import React from 'react';
import styled from '@emotion/styled';
import { usePopper } from 'react-popper';
import { zIndices } from '@graphite/constants';
import type { Placement, PositioningStrategy } from '@popperjs/core/lib';
import type { TSx } from '@graphite/types';

import Box from '../Box';
import Portal from '../Portal';
import useHover from './use-hover';

export type TProps = $ReadOnly<{|
	elRef: $ReadOnly<{
		current: ?Element,
	}>,
	placement?: Placement,
	children: React$Node,
	offset?: $ReadOnlyArray<number>,
	maxWidth?: string,
	variant?:
		| 'darkblue.rounded.xsm'
		| 'darkblue.rounded.sm'
		| 'blue.rounded.xsm'
		| 'blue.rounded.sm',
	strategy?: PositioningStrategy,
	trigger?: 'always' | 'hover',
	delayShow?: number,
	sx?: TSx,
|}>;

const Wrapper = styled(Box)`
	z-index: ${zIndices.tooltip};
`;

const Tooltip = ({
	elRef,
	children,
	placement = 'bottom',
	offset = [0, 12],
	maxWidth = 'none',
	variant = 'darkblue.rounded.sm',
	strategy = 'absolute',
	trigger = 'hover',
	delayShow = 700,
	sx = {},
}: TProps) => {
	const [popperElement, setPopperElement] = React.useState(null);
	const { styles, attributes } = usePopper(elRef.current, popperElement, {
		placement,
		strategy,
		modifiers: [
			{
				name: 'flip',
				options: {
					fallbackPlacements: ['top', 'bottom', 'right', 'left'],
				},
			},
			{
				name: 'offset',
				options: {
					offset,
				},
			},
		],
	});

	const [isShow, setShow] = React.useState(trigger === 'always');

	const timeoutID = React.useRef(null);
	const isHovering = useHover(elRef);

	React.useEffect(() => {
		if (trigger === 'always') return;

		clearTimeout(timeoutID.current);
		timeoutID.current = null;

		if (isHovering) timeoutID.current = setTimeout(() => setShow(true), delayShow);
		else setShow(false);
	}, [delayShow, isHovering, trigger]);

	return (
		isShow && (
			<Portal>
				<Wrapper
					//  eslint-disable-next-line react/jsx-props-no-spreading
					{...attributes.popper}
					ref={setPopperElement}
					style={styles.popper}
					maxWidth={maxWidth}
					variant={`tooltip.${variant}`}
					sx={sx}
				>
					{children}
				</Wrapper>
			</Portal>
		)
	);
};

export default React.memo<TProps>(Tooltip);
