// @flow
import type { TSpecsWidget, TSpec } from '@graphite/types';

import { colorspecBootstrap } from './colorspec-bootstrap';
import { colorspecUneon } from './colorspec-uneon';
import { listForFontsLib } from './font-family-native';

export const specsPreset: { [string]: TSpec } = {
	gridspecId_PRESET: {
		userId: null,
		_id: 'gridspecId_PRESET',
		kind: 'gridspec',
		name: 'Bootstrap',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: 'site__REPLACE_WITH_NEW_ID',
		unit: 4,

		breakpoints: {
			phone_p: {
				active: true,
				breakpoint: 576,
				columns: 6,
				gutter: 30,
				gutterUnit: 'px',
				container: 100,
				containerUnit: '%',
				padding: 0,
				paddingUnit: 'px',
			},
			phone_l: {
				active: false,
				breakpoint: 768,
				columns: 6,
				gutter: 30,
				gutterUnit: 'px',
				container: 570,
				containerUnit: 'px',
				padding: 0,
				paddingUnit: 'px',
			},
			tablet_p: {
				active: true,
				breakpoint: 992,
				columns: 12,
				gutter: 30,
				gutterUnit: 'px',
				container: 750,
				containerUnit: 'px',
				padding: 0,
				paddingUnit: 'px',
			},
			tablet_l: {
				active: false,
				breakpoint: 1200,
				columns: 12,
				gutter: 30,
				gutterUnit: 'px',
				container: 750,
				containerUnit: 'px',
				padding: 0,
				paddingUnit: 'px',
			},
			desktop: {
				active: true,
				breakpoint: 1200,
				columns: 12,
				gutter: 30,
				gutterUnit: 'px',
				container: 990,
				containerUnit: 'px',
				padding: 0,
				paddingUnit: 'px',
			},
			desktop_hd: {
				active: false,
				breakpoint: 1440,
				columns: 12,
				gutter: 30,
				gutterUnit: 'px',
				container: 1140,
				containerUnit: 'px',
				padding: 0,
				paddingUnit: 'px',
			},
		},
	},

	colorspecBootstrapId_PRESET: {
		userId: 'userId_PRESET',
		_id: 'colorspecBootstrapId_PRESET',
		kind: 'colorspec',
		name: 'Bootstrap',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: 'site__REPLACE_WITH_NEW_ID',

		activeTheme: 'light',

		generator: {
			isEnabled: false,
			baseColor: null,
		},

		...colorspecBootstrap,
	},
	colorspecUneonId_PRESET: {
		userId: 'userId_PRESET',
		_id: 'colorspecUneonId_PRESET',
		kind: 'colorspec',
		name: 'Uneon',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: 'site__REPLACE_WITH_NEW_ID',

		activeTheme: 'light',

		generator: {
			isEnabled: false,
			baseColor: null,
		},

		...colorspecUneon,
	},
	widgetspecId_PRESET: ({
		userId: 'userId_PRESET',
		_id: 'widgetspecId_PRESET',
		kind: 'widgetspec',
		name: 'Bootstrap',

		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: 'site__REPLACE_WITH_NEW_ID',

		block: [],

		col: [],

		text: [
			{
				_id: 'header1',
				target: 'text',
				kind: 'design',
				userId: 'userId_PRESET',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: 'site__REPLACE_WITH_NEW_ID',
				section: 'header',
				name: 'Header 1',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 40,
						textHeight: 58,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'header2',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'header',
				name: 'Header 2',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 32,
						textHeight: 38,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'header3',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'header',
				name: 'Header 3',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 28,
						textHeight: 33,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'header4',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'header',
				name: 'Header 4',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 24,
						textHeight: 29,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'header5',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'header',
				name: 'Header 5',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 20,
						textHeight: 24,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'header6',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'header',
				name: 'Header 6',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 16,
						textHeight: 19,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'body-text',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'body',
				name: 'Text',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '400',
						fontSize: 18,
						textHeight: 27,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'body-lead',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'body',
				name: 'Lead',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '300',
						fontSize: 20,
						textHeight: 30,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
			{
				_id: 'body-button',
				target: 'text',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				section: 'body',
				name: 'Button',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '500',
						fontSize: 16,
						textHeight: 24,
						textHeightUnit: 'px',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							text: {
								color: 'text.primary',
								shadow: null,
							},
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
						},
					},
				},
			},
		],

		image: [],

		icon: [
			{
				_id: 'icon-flat',
				target: 'icon',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Flat',
				breakpoints: {
					desktop: {
						border: null,
						padding: 0,
						paddingUnit: 'px',
						radius: 'null',
						iconSize: 24,
						iconSizeUnit: 'px',
						normal: {
							container: {
								background: null,
								border: null,
								overlay: null,
								shadow: null,
							},
							text: {
								color: 'text.primary',
								shadow: null,
							},
						},
					},
				},
			},
		],

		button: [
			{
				_id: 'button-normal',
				target: 'button',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Normal',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 18,
						paddingUnit: 'px',
						radius: 'small',
						textDesign: 'body-button',
						textMargin: 0,
						textMarginUnit: 'px',
						btnIconSize: 16,
						btnIconSizeUnit: 'px',
						iconMargin: 6,
						iconMarginUnit: 'px',
						normal: {
							container: {
								border: 'bg.accentplus',
								background: [
									{
										value: 'bg.accent',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.oncolor',
								shadow: null,
							},
						},
						border: 'thin',
						hover: {
							container: {
								border: 'bg.accentminus',
								background: [
									{
										value: 'bg.accentplus',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
			{
				_id: 'button-rounded',
				target: 'button',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Rounded',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 24,
						paddingUnit: 'px',
						radius: 'rounded',
						textDesign: 'body-button',
						textMargin: 0,
						textMarginUnit: 'px',
						btnIconSize: 16,
						btnIconSizeUnit: 'px',
						iconMargin: 6,
						iconMarginUnit: 'px',
						normal: {
							container: {
								border: 'bg.accentplus',
								background: [
									{
										value: 'bg.accent',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.oncolor',
								shadow: null,
							},
						},
						border: 'thin',
						hover: {
							container: {
								border: 'bg.accentminus',
								background: [
									{
										value: 'bg.accentplus',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
			{
				_id: 'button-outlined',
				target: 'button',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Outlined',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 18,
						paddingUnit: 'px',
						radius: 'small',
						textDesign: 'body-button',
						textMargin: 0,
						textMarginUnit: 'px',
						btnIconSize: 16,
						btnIconSizeUnit: 'px',
						iconMargin: 6,
						iconMarginUnit: 'px',
						normal: {
							container: {
								border: 'bg.accent',
								background: null,
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.accent',
								shadow: null,
							},
						},
						border: 'thick',
						hover: {
							container: {
								border: 'bg.accentminus',
								background: [
									{
										value: 'bg.accentplus',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.oncolor',
								shadow: null,
							},
						},
					},
				},
			},
			{
				_id: 'button-flat',
				target: 'button',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Flat',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 0,
						paddingUnit: 'px',
						radius: 'small',
						textDesign: 'body-button',
						textMargin: 0,
						textMarginUnit: 'px',
						btnIconSize: 16,
						btnIconSizeUnit: 'px',
						iconMargin: 6,
						iconMarginUnit: 'px',
						normal: {
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.accent',
								shadow: null,
							},
						},
						border: 'thin',
						hover: {
							container: {
								border: null,
								background: null,
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.accentplus',
								shadow: null,
							},
						},
					},
				},
			},
		],

		stack: [
			{
				_id: 'stack-background',
				target: 'stack',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Background',
				breakpoints: {
					desktop: {
						normal: {
							container: {
								border: null,
								background: [
									{
										value: 'bg.primaryalt',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
		],
		rectangle: [
			{
				_id: 'rectangle-background',
				target: 'rectangle',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Background',
				breakpoints: {
					desktop: {
						normal: {
							container: {
								border: null,
								background: [
									{
										value: {
											entryId: null,
											shadeId: null,
											snapshot: '#E9ECEF',
										},
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
		],
		input: [
			{
				_id: 'input-normal',
				target: 'input',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Normal',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 16,
						paddingUnit: 'px',
						radius: 'medium',
						textDesign: 'body-text',
						textMargin: 0,
						textMarginUnit: 'px',
						border: 'thin',
						normal: {
							container: {
								border: 'bg.primaryalt',
								background: [],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.primary',
								shadow: null,
							},
							placeholder: {
								color: 'text.secondary',
								shadow: null,
							},
						},
					},
				},
			},
		],
		textarea: [
			{
				_id: 'textarea-normal',
				target: 'textarea',
				kind: 'design',
				userId: null,
				display: 'normal',
				protoId: null,
				removedAt: null,
				scope: 'site',
				scopeId: null,
				name: 'Normal',
				breakpoints: {
					desktop: {
						padding: 16,
						paddingUnit: 'px',
						radius: 'medium',
						textDesign: 'body-text',
						textMargin: 0,
						textMarginUnit: 'px',
						border: 'thin',
						paddingSides: {
							top: 12,
							bottom: 12,
						},
						normal: {
							container: {
								border: 'bg.primaryalt',
								background: [],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.primary',
								shadow: null,
							},
							placeholder: {
								color: 'text.secondary',
								shadow: null,
							},
						},
					},
				},
			},
		],
	}: TSpecsWidget),
	effectspecId_PRESET: {
		userId: null,
		_id: 'effectspecId_PRESET',
		kind: 'effectspec',
		name: 'Bootstrap',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: 'site__REPLACE_WITH_NEW_ID',

		radii: [
			{
				id: 'small',
				name: 'Small',
				size: 6,
				sides: null,
			},
			{
				id: 'medium',
				name: 'Medium',
				size: 8,
				sides: null,
			},
			{
				id: 'large',
				name: 'Large',
				size: 10,
				sides: null,
			},
			{
				id: 'rounded',
				name: 'Rounded',
				size: 999,
				sides: null,
			},
		],

		borders: [
			{
				id: 'thin',
				name: 'Thin',
				kind: 'solid',
				size: 1,
				sides: null,
			},
			{
				id: 'thick',
				name: 'Thick',
				kind: 'solid',
				size: 2,
				sides: null,
			},
			{
				id: 'underlined',
				name: 'Underlined',
				kind: 'solid',
				size: 2,
				sides: {
					n: 0,
					e: 0,
					w: 0,
				},
			},
		],

		shadows: [
			{
				id: 'small',
				name: 'Small',
				kind: 'drop',
				x: 0,
				y: 5,
				blur: 10,
				spread: 0,
				color: {
					entryId: null,
					shadeId: null,
					snapshot: '#c74cff33',
				},
			},
			{
				id: 'medium',
				name: 'Medium',
				kind: 'drop',
				x: 0,
				y: 5,
				blur: 10,
				spread: 0,
				color: {
					entryId: null,
					shadeId: null,
					snapshot: '#0000001a',
				},
			},
			{
				id: 'large',
				name: 'Large',
				kind: 'drop',
				x: 0,
				y: 10,
				blur: 20,
				spread: 0,
				color: {
					entryId: null,
					shadeId: null,
					snapshot: '#0000001a',
				},
			},
		],

		transitions: [
			{
				id: 'hover',
				name: 'Hover',
				easing: 'ease',
				time: 200,
				delay: 0,
				curve: null,
			},
			{
				id: 'closing',
				name: 'Closing',
				easing: 'ease',
				time: 200,
				delay: 0,
				curve: null,
			},
			{
				id: 'closing-big-objects',
				name: 'Closing Big Objects',
				easing: 'ease',
				time: 200,
				delay: 0,
				curve: null,
			},
		],
	},
};

export default specsPreset;
