// @flow
import React from 'react';
import styled from '@emotion/styled';
import { zIndices } from '@graphite/constants';
import type { TSx } from '@graphite/types';
import Portal from '../Portal';
import Box from '../Box';

const DEFAULT_SIZE_OVERLY = 30;

type TFakeMouseEvent = MouseEvent => void;

export type TProps = $ReadOnly<{|
	children: React$Node,
	topPosition?: number,
	onClose?: TFakeMouseEvent,
	sx?: ?TSx,
	className?: string,
|}>;

const containerSx = {
	backgroundColor: 'rgba(0,0,0,0.5)',
	position: 'fixed',
	zIndex: zIndices.modalFullScreen,
	display: 'flex',
	flexDirection: 'column',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	padding: `${DEFAULT_SIZE_OVERLY}px`,
	cursor: 'pointer',
};

const Body = styled(Box)`
	background-color: ${({ theme }) => theme.colors.spec.lightblue10};
	overflow-y: overlay;
	overflow-x: hidden;
	flex: 1;
	cursor: auto;

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-button {
		display: none;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: ${({ theme }) => theme.radii.md.all};
	}

	&::-webkit-scrollbar-track:hover {
		background-color: ${({ theme }) => theme.colors.bg.primaryaltplus};
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;

		border-radius: ${({ theme }) => theme.radii.md.all};
	}

	&:hover::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.bg.secondaryminus};
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: ${({ theme }) => theme.colors.text.tertiary};
	}
`;

const ModalFullScreen = (
	{ children, onClose = null, topPosition = 0, sx = null, className }: TProps,
	ref,
) => {
	const overlayBoxThemedSx = React.useMemo(
		() => ({
			...containerSx,
			...sx,
			...(topPosition ? { top: `${topPosition}px` } : {}),
		}),
		[sx, topPosition],
	);
	const mainContenerRef = React.useRef(null);
	const handleOnClose = React.useCallback(
		e => {
			if (mainContenerRef.current === e.target) onClose(e);
		},
		[onClose],
	);
	return (
		<Portal>
			<Box
				sx={overlayBoxThemedSx}
				onClick={handleOnClose}
				ref={mainContenerRef}
				className={className}
			>
				<Body ref={ref}>{children}</Body>
			</Box>
		</Portal>
	);
};

export default React.memo<TProps>(React.forwardRef(ModalFullScreen));
