// @flow
const SYMBOL_LEVELS = {
	page: 1,
	site: 2,
	project: 3,
	user: 4,
	published: 5,
	premarket: 6,
	market: 7,
	system: 8,
};

export const symGt = (a: string, b: string) => SYMBOL_LEVELS[a] > SYMBOL_LEVELS[b];

export default SYMBOL_LEVELS;
