// @flow

export const config = {
	storageBucket: `${process.env.FIREBASE_PROJECT || ''}.appspot.com`,
	messagingSenderId: process.env.MESSAGINGSENDER_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID,
	databaseURL: `https://${process.env.FIREBASE_PROJECT || ''}.firebaseio.com`,
	projectId: process.env.FIREBASE_PROJECT,
	authDomain: `${process.env.FIREBASE_PROJECT || ''}.firebaseapp.com`,
	appHome: process.env.HOSTING_HOME_URL
		? `${process.env.HOSTING_HOME_URL}`
		: '//localhost:3011/',
	robots: process.env.ROBOTS,
	appId: process.env.FIREBASE_APP_ID,
	appUrl: process.env.HOSTING_EDITOR_URL
		? `${process.env.HOSTING_EDITOR_URL}`
		: '//localhost:3001/',
	apiKey: process.env.FIREBASE_API_KEY,
	apiUnsplashKey: process.env.UNSPLASH_API_KEY,
	apiGoogleFontsKey: process.env.GOOGLE_FONTS_API_KEY || '',
};

export default config;
