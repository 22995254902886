// @flow
import type { TSpecs, TSpecsColor } from '@graphite/types';

import { defaultDevice } from './widgets';

export const _siteId = '5beabb5c8307f50ee81a5c2f';
export const _pageId = '5bf28387f351d739e9dcddbd';
export const _userId = '5bf28387f351d739e9dcddbc';

// Доступные пресеты
export const _blockId = '5bf28387cc46d4742477e51d';
export const _blockInlId = '5bf28387cc46d4742477e555';
export const _colId = '5bf28387c8afb59d4f929690';
export const _btnId = '5bf28387c8a4e5b97b2bdd5f';
export const _textId = '5bf283873108a0677ff82a30';
export const _imgId = '5bf283870af147268a2623f9';
export const _imgId2 = '5bf283870af147268a262111';
export const _comId1 = '5c0a5894a0371f94debbb970';
export const _comId2 = '5c0a5916cc81fe0455f9b38f';
export const _comId3 = '5c0a5a469ce7c2717ab9eee4';
export const _comId4 = '5c0a5a5519d9d5383c81e7c0';

// Доступные символы
export const _symColId = '5c0e3974d838c43aae3c154c';
export const _symTextId = '5c0e36d09d506980d3e43af2';
export const _symCardId = '5bf28387375e0bc85b02096e';
export const _symCardColId = '5bf283878df8b9de38a52d12';
export const _symCardTextId = '5bf283877777ce9efff50e34';
export const _symCardImgId = '5bf283878268a18da2c7c152';
export const _symCardBtnId = '5bf283874b84d1823a8169b0';

// Виджеты на странице
export const _blockId1 = '5bf283874f05a78f436936ed';
export const _colId11 = '5bf28387e32edd91bf9eae4e';
export const _textId111 = '5bf28387d97ef368b2c73838';
export const _textId112 = '5bf283870ee542629cfa9dfb';
export const _colId12 = '5bf28387b9ab833a4ed5dc51';
export const _btnId121 = '5bf28387f3786498cd91591e';
export const _btnId122 = '5bf28387cafa7319c13ad084';
export const _colId13 = '5c0a6c2c839ed117d0571cc5';
export const _colId14 = '5c0e399461c01b1c75f7f0e3';
export const _colId15 = '5c0e51aa0bf8d73bd32765b3';
export const _cardId151 = '5c0e51d3f96464d783496099';
export const _colId16 = '5c0e56de05eaabafebd0cba8';
export const _textId161 = '5c0e56e37725d13db1015c47';

export const _blockId2 = '5bf28387f6b05f7497e82c45';
export const _colId21 = '5bf28387fcf6586ac833c169';
export const _textId211 = '5bf283875a33d104da3192f6';
export const _cardId212 = '5bf28387204e4dcfd856e6bf';
export const _textId213 = '5c0f6ced3df5379372f4898e';
export const _colId22 = '5bf283879cabdfb0d2df4e3e';
export const _cardId221 = '5bf28387a20e4ed1566c41df';
export const _imgId222 = '5bf283871927c35d7e6e5ef4';
export const _colId23 = '5c0f740d8064d357c1bf2212';
export const _textId231 = '5c0f741185d6849de71e7e17';

export const _blockInId1 = '5c0f741185d6849de71e7ee1';
export const _colId100 = '5c0f740d8064d357c1bf2100';
export const _colId101 = '5c0f740d8064d357c1bf2101';

export const _block1SymTest1 = '5d1c7ae6d9d217fbdaa2f570';
export const _col1SymTest1 = '5d1c7ae6726594219ea3efc1';
export const _col2SymTest1 = '5d1c7ae727cd945b744d1d71';
export const _block1InstTest1 = '5d1c7ae8f2ab584a6907d19f';

export const _photo1 =
	'https://www.comedywildlifephoto.com/images/gallery/5/00000145_p.jpg';
export const _photo2 =
	'https://www.comedywildlifephoto.com/images/gallery/8/00000328_p.jpg';

export const widgets = {
	// state.widgets
	// TODO пропачить axios для отправки null на сервер
	// Доступные пресеты
	[_blockId]: {
		_id: _blockId,
		kind: 'block',
		name: 'Блок',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		userId: 'userId',
	},
	[_blockInlId]: {
		_id: _blockInlId,
		kind: 'stack',
		name: 'Внутренний блок',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		userId: 'userId',
	},
	[_colId]: {
		_id: _colId,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		userId: 'userId',
	},
	[_btnId]: {
		_id: _btnId,
		kind: 'button',
		name: 'Кнопка',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		text: 'Lorem',
		padding: {
			left: 10,
			right: 10,
			top: 10,
			bottom: 10,
		},
		userId: 'userId',
	},
	[_textId]: {
		_id: _textId,
		kind: 'text',
		name: 'Текст',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		text: 'Lorem ipsum dolor sit amet, ea elit eruditi sit.',
		userId: 'userId',
	},
	[_imgId]: {
		_id: _imgId,
		kind: 'image',
		name: 'Картинка',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		src: _photo1,
		alt: 'Картинка',
		userId: 'userId',
	},
	[_imgId2]: {
		_id: _imgId2,
		kind: 'image',
		name: 'Картинка',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		src: _photo2,
		alt: 'Картинка',
		userId: 'userId',
	},
	[_comId1]: {
		_id: _comId1,
		kind: 'block',
		name: 'Блок',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		children: { [_comId2]: _comId2 },
		order: {
			[`${defaultDevice}`]: { [_comId2]: 0 },
		},
		sizes: {
			[_comId2]: {
				[`${defaultDevice}`]: { width: 1, margin: { left: 0, right: 0 } },
			},
		},
		userId: 'userId',
	},
	[_comId2]: {
		_id: _comId2,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		children: { [_comId3]: _comId3, [_comId4]: _comId4 },
		order: {
			[`${defaultDevice}`]: { [_comId3]: 0, [_comId4]: 1 },
		},
		userId: 'userId',
	},
	[_comId3]: {
		_id: _comId3,
		kind: 'image',
		name: 'Картинка',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		src: _photo1,
		alt: 'Картинка',
		userId: 'userId',
	},
	[_comId4]: {
		_id: _comId4,
		kind: 'text',
		name: 'Текст',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		text: 'Lorem ipsum dolor sit amet, ea elit eruditi sit.',
		userId: 'userId',
	},

	// Доступные символы
	[_symColId]: {
		_id: _symColId,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'symbol',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		userId: 'userId',
	},
	[_symTextId]: {
		_id: _symTextId,
		kind: 'text',
		name: 'Текст',
		display: 'symbol',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		text: 'Usu verear vivendo mnesarchum no, ad omnis audire scripta usu.',
		userId: 'userId',
	},
	[_symCardId]: {
		_id: _symCardId,
		kind: 'stack',
		name: 'Карточка',
		display: 'symbol',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		children: { [_symCardColId]: _symCardColId },
		userId: 'userId',
		// sizes: {
		// 	[_symCardColId]: {
		// 		phone_p: { width: 1, margin: { left: 0, right: 0 } },
		// 		phone_l: { width: 1, margin: { left: 0, right: 0 } },
		// 		tablet_p: { width: 1, margin: { left: 0, right: 0 } },
		// 		tablet_l: { width: 1, margin: { left: 0, right: 0 } },
		// 		desktop: { width: 1, margin: { left: 0, right: 0 } },
		// 		[defaultDevice]: { width: 1, margin: { left: 0, right: 0 } },
		// 	},
		// },
	},
	[_symCardColId]: {
		_id: _symCardColId,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		children: {
			[_symCardTextId]: _symCardTextId,
			[_symCardImgId]: _symCardImgId,
			[_symCardBtnId]: _symCardBtnId,
		},
		order: {
			[`${defaultDevice}`]: {
				[_symCardTextId]: 0,
				[_symCardImgId]: 1,
				[_symCardBtnId]: 2,
			},
		},
		positions: { [_symCardImgId]: 'absolute' },
		userId: 'userId',
	},
	[_symCardTextId]: {
		_id: _symCardTextId,
		kind: 'text',
		name: 'Текст',
		display: 'normal',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		text: 'Описание',
		userId: 'userId',
	},
	[_symCardImgId]: {
		_id: _symCardImgId,
		kind: 'image',
		name: 'Картинка',
		display: 'normal',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		src: _photo2,
		alt: 'Заетс',
		offset: {
			desktop_hd: {
				top: 10,
				left: 15,
				bottom: 0,
				right: 15,
			},
			desktop: { top: 15, left: 25, bottom: 0, right: 15 },
		},
		userId: 'userId',
	},
	[_symCardBtnId]: {
		_id: _symCardBtnId,
		kind: 'button',
		name: 'Кнопка',
		display: 'normal',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		text: 'Купить',
		padding: {
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
		},
		userId: 'userId',
	},

	// Виджеты на странице
	[_pageId]: {
		_id: _pageId,
		kind: 'page',
		size: {},
		name: 'Page',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_blockId1]: _blockId1, [_blockId2]: _blockId2 },
		order: {
			[`${defaultDevice}`]: { [_blockId1]: 0, [_blockId2]: 1 },
		},
		userId: 'userId',
	},
	[_blockId1]: {
		_id: _blockId1,
		kind: 'block',
		name: 'Блок',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: {
			[_colId11]: _colId11,
			[_colId12]: _colId12,
			[_colId13]: _colId13,
			[_colId14]: _colId14,
			[_colId15]: _colId15,
			[_colId16]: _colId16,
		},
		order: {
			[`${defaultDevice}`]: {
				[_colId11]: 0,
				[_colId12]: 1,
				[_colId13]: 2,
				[_colId14]: 3,
				[_colId15]: 4,
				[_colId16]: 5,
			},
		},
		sizes: {
			[_colId11]: {
				[`${defaultDevice}`]: { width: 0.25, margin: { left: 0, right: 0.05 } },
			},
			[_colId12]: {
				[`${defaultDevice}`]: { width: 0.25, margin: { left: 0, right: 0.05 } },
			},
			[_colId13]: {
				[`${defaultDevice}`]: { width: 0.09, margin: { left: 0, right: 0 } },
			},
			[_colId14]: {
				[`${defaultDevice}`]: { width: 0.09, margin: { left: 0, right: 0 } },
			},
			[_colId15]: {
				[`${defaultDevice}`]: { width: 0.2, margin: { left: 0.02, right: 0 } },
			},
			[_colId16]: {
				[`${defaultDevice}`]: { width: 1, margin: { left: 0, right: 0 } },
			},
		},
		userId: 'userId',
	},
	[_colId11]: {
		_id: _colId11,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_textId111]: _textId111, [_textId112]: _textId112 },
		order: {
			[`${defaultDevice}`]: { [_textId111]: 0, [_textId112]: 1 },
		},
		userId: 'userId',
	},
	[_textId111]: {
		_id: _textId111,
		kind: 'text',
		name: 'Текст',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		text: 'Diam debitis ne per, elit quaeque suscipit eum et.',
		userId: 'userId',
	},
	[_textId112]: {
		_id: _textId112,
		kind: 'text',
		name: 'Текст',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		text: 'Hic qui laborum ullam consequatur et dolores.',
		userId: 'userId',
	},
	[_colId12]: {
		_id: _colId12,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_btnId121]: _btnId121, [_btnId122]: _btnId122 },
		order: {
			[`${defaultDevice}`]: { [_btnId121]: 0, [_btnId122]: 1 },
		},
		positions: { [_btnId122]: 'absolute' },
		userId: 'userId',
	},
	[_btnId121]: {
		_id: _btnId121,
		kind: 'button',
		name: 'Кнопка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		text: 'Harum cetero pro ne',
		padding: {
			left: 10,
			right: 10,
			top: 10,
			bottom: 10,
		},
		userId: 'userId',
	},
	[_btnId122]: {
		_id: _btnId122,
		kind: 'button',
		name: 'Кнопка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		text: 'Ipsum',
		padding: {
			left: 10,
			right: 10,
			top: 10,
			bottom: 10,
		},
		offset: {
			desktop_hd: { top: 10, left: 15 },
			desktop: { top: 55, left: 0 },
		},
		userId: 'userId',
	},
	[_colId13]: {
		_id: _colId13,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		userId: 'userId',
	},
	[_colId14]: {
		_id: _colId14,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		userId: 'userId',
	},
	[_colId15]: {
		_id: _colId15,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		modified: [_cardId151],
		children: { [_cardId151]: _cardId151 },
		order: {
			[`${defaultDevice}`]: { [_cardId151]: 0 },
		},
		userId: 'userId',
	},
	[_cardId151]: {
		_id: _cardId151,
		display: 'normal',
		protoId: _symCardId,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		userId: 'userId',
		// TODO: @Laplandin разобраться с источником размеров
	},
	[_colId16]: {
		_id: _colId16,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		modified: [_textId161],
		children: { [_textId161]: _textId161 },
		userId: 'userId',
	},
	[_textId161]: {
		_id: _textId161,
		display: 'normal',
		protoId: _symTextId,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		userId: 'userId',
	},
	[_blockInId1]: {
		_id: _blockInId1,
		kind: 'stack',
		name: 'Вложенный Блок',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_colId101]: _colId101 },
		order: {
			[`${defaultDevice}`]: { [_colId101]: 0 },
		},
		userId: 'userId',
		// sizes: {
		// 	[_colId101]: {
		// 		phone_p: { width: 1, margin: { left: 0, right: 0 } },
		// 		phone_l: { width: 1, margin: { left: 0, right: 0 } },
		// 		tablet_p: { width: 1, margin: { left: 0, right: 0 } },
		// 		tablet_l: { width: 1, margin: { left: 0, right: 0 } },
		// 		desktop: { width: 1, margin: { left: 0, right: 0 } },
		// 		[defaultDevice]: { width: 1, margin: { left: 0, right: 0 } },
		// 	},
		// },
	},
	[_blockId2]: {
		_id: _blockId2,
		kind: 'block',
		name: 'Блок',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: {
			[_colId21]: _colId21,
			[_colId22]: _colId22,
			[_colId23]: _colId23,
			[_colId100]: _colId100,
		},
		order: {
			[`${defaultDevice}`]: { [_colId21]: 0, [_colId22]: 1, [_colId23]: 2 },
		},
		sizes: {
			[_colId21]: {
				[`${defaultDevice}`]: { width: 0.33, margin: { left: 0, right: 0 } },
			},
			[_colId22]: {
				[`${defaultDevice}`]: { width: 0.33, margin: { left: 0, right: 0 } },
			},
			[_colId23]: {
				[`${defaultDevice}`]: { width: 0.34, margin: { left: 0, right: 0 } },
			},
			[_colId100]: {
				[`${defaultDevice}`]: {
					width: 0.34,
					margin: { left: 0.33, right: 0.33 },
				},
			},
		},
		userId: 'userId',
	},
	[_colId21]: {
		_id: _colId21,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: {
			[_textId211]: _textId211,
			[_cardId212]: _cardId212,
			[_textId213]: _textId213,
		},
		order: {
			[`${defaultDevice}`]: { [_textId211]: 0, [_cardId212]: 1, [_textId213]: 2 },
		},
		userId: 'userId',
	},
	[_textId211]: {
		_id: _textId211,
		kind: 'text',
		name: 'Текст',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		text: 'Ei vix nostrud imperdiet scripserit.',
		userId: 'userId',
	},
	[_cardId212]: {
		_id: _cardId212,
		display: 'normal',
		protoId: _symCardId,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		children: {},
		userId: 'userId',
	},
	[_textId213]: {
		_id: _textId213,
		display: 'normal',
		protoId: _symTextId,
		scope: 'page',
		scopeId: _pageId,
		text: 'At sit ut sed.',
		modified: [],
		userId: 'userId',
	},
	[_colId22]: {
		_id: _colId22,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_cardId221]: _cardId221, [_imgId222]: _imgId222 },
		order: {
			[`${defaultDevice}`]: { [_cardId221]: 0, [_imgId222]: 1 },
		},
		userId: 'userId',
	},
	[_colId100]: {
		_id: _colId100,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_textId111]: _textId111, [_blockInId1]: _blockInId1 },
		order: {
			[`${defaultDevice}`]: { [_textId111]: 0, [_blockInId1]: 1 },
		},
		userId: 'userId',
	},
	[_colId101]: {
		_id: _colId101,
		kind: 'col',
		sizes: {}, // FIXME это шляпа
		name: 'Колонка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: { [_imgId2]: _imgId2 },
		userId: 'userId',
	},
	[_cardId221]: {
		_id: _cardId221,
		display: 'normal',
		protoId: _symCardId,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		userId: 'userId',
	},
	[_imgId222]: {
		_id: _imgId222,
		kind: 'image',
		name: 'Картинка',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		src: 'https://www.comedywildlifephoto.com/images/gallery/3/00000143_p.jpg',
		alt: 'Картинка',
		userId: 'userId',
	},
	[_colId23]: {
		_id: _colId23,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		modified: [_textId231],
		children: { [_textId231]: _textId231 },
		userId: 'userId',
	},
	[_textId231]: {
		_id: _textId231,
		display: 'normal',
		protoId: _symTextId,
		scope: 'page',
		scopeId: _pageId,
		text: 'Risus ultricies.',
		modified: [],
		userId: 'userId',
	},
	[_block1SymTest1]: {
		_id: _block1SymTest1,
		kind: 'block',
		name: 'Блок',
		display: 'normal',
		protoId: null,
		scope: 'page',
		scopeId: _pageId,
		children: {
			[_col1SymTest1]: _col1SymTest1,
			[_col2SymTest1]: _col2SymTest1,
		},
		order: {
			[`${defaultDevice}`]: { [_col2SymTest1]: 0, [_col1SymTest1]: 1 },
		},
		sizes: {
			[_col1SymTest1]: {
				[`${defaultDevice}`]: { width: 0.5, margin: { left: 0, right: 0 } },
			},
			[_col2SymTest1]: {
				[`${defaultDevice}`]: { width: 0.5, margin: { left: 0, right: 0 } },
			},
		},
		userId: 'userId',
	},
	[_col1SymTest1]: {
		_id: _col1SymTest1,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		userId: 'userId',
	},
	[_col2SymTest1]: {
		_id: _col2SymTest1,
		display: 'normal',
		protoId: _symColId,
		scope: 'page',
		scopeId: _pageId,
		userId: 'userId',
	},
	[_block1InstTest1]: {
		_id: _block1InstTest1,
		display: 'normal',
		protoId: _block1SymTest1,
		scope: 'page',
		scopeId: _pageId,
		modified: [],
		userId: 'userId',
	},
};

export const specs: TSpecs = {
	gridspec1: {
		_id: 'gridspec1',
		kind: 'gridspec',
		name: 'The Griid',

		display: 'normal',
		protoId: null,
		scope: 'site',
		scopeId: _siteId,
		userId: _userId,
		unit: 4,

		breakpoints: {
			phone_p: {
				active: true,
				breakpoint: 0,
				container: 320,
				padding: 0,
				paddingUnit: 'px',
				columns: 8,
				containerUnit: 'px',
				gutter: 0,
				gutterUnit: 'px',
			},
			phone_l: {
				active: false,
				breakpoint: 576,
				container: 576,
				padding: 0,
				paddingUnit: 'px',
				columns: 8,
				containerUnit: 'px',
				gutter: 1,
				gutterUnit: 'px',
			},
			tablet_p: {
				active: true,
				breakpoint: 768,
				container: 768,
				padding: 0,
				paddingUnit: 'px',
				columns: 12,
				containerUnit: 'px',
				gutter: 1,
				gutterUnit: 'px',
			},
			tablet_l: {
				active: false,
				breakpoint: 992,
				container: 992,
				padding: 0,
				paddingUnit: 'px',
				columns: 12,
				containerUnit: 'px',
				gutter: 1,
				gutterUnit: 'px',
			},
			desktop: {
				active: true,
				breakpoint: 1200,
				container: 1200,
				padding: 0,
				paddingUnit: 'px',
				columns: 16,
				containerUnit: 'px',
				gutter: 4,
				gutterUnit: 'px',
			},
			[`${defaultDevice}`]: {
				active: false,
				breakpoint: 1440,
				container: 1440,
				padding: 0,
				paddingUnit: 'px',
				columns: 16,
				containerUnit: 'px',
				gutter: 1,
				gutterUnit: 'px',
			},
		},
	},

	colorspec1: ({
		_id: 'colorspec1',
		kind: 'colorspec',
		name: 'The Collors',
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: _siteId,
		userId: _userId,

		activeTheme: 'theme0',

		generator: {
			isEnabled: false,
			baseColor: null,
		},

		palette: [
			{
				id: 'entry0',
				name: 'Green',
				color: '#6DCE87',
				shades: [
					{ color: '#6DCE87', id: 'entry0--4' },
					{ color: '#6DCE87', id: 'entry0--3' },
					{ color: '#6DCE87', id: 'entry0--2' },
					{ color: '#6DCE87', id: 'entry0--1' },
					{ color: '#6DCE87', id: 'entry0' },
					{ color: '#6DCE87', id: 'entry0-1' },
					{ color: '#6DCE87', id: 'entry0-2' },
					{ color: '#6DCE87', id: 'entry0-3' },
					{ color: '#6DCE87', id: 'entry0-4' },
				],
			},
			{
				id: 'entry1',
				name: 'Cyan',
				color: '#6DB4CE',
				shades: [
					{ color: '#6DB4CE', id: 'entry1--4' },
					{ color: '#6DB4CE', id: 'entry1--3' },
					{ color: '#6DB4CE', id: 'entry1--2' },
					{ color: '#6DB4CE', id: 'entry1--1' },
					{ color: '#6DB4CE', id: 'entry1' },
					{ color: '#6DB4CE', id: 'entry1-1' },
					{ color: '#6DB4CE', id: 'entry1-2' },
					{ color: '#6DB4CE', id: 'entry1-3' },
					{ color: '#6DB4CE', id: 'entry1-4' },
				],
			},
			{
				id: 'entry2',
				name: 'Light Violet',
				color: '#D1CFD6',
				shades: [
					{ color: '#D1CFD6', id: 'entry2--4' },
					{ color: '#D1CFD6', id: 'entry2--3' },
					{ color: '#D1CFD6', id: 'entry2--2' },
					{ color: '#D1CFD6', id: 'entry2--1' },
					{ color: '#D1CFD6', id: 'entry2' },
					{ color: '#D1CFD6', id: 'entry2-1' },
					{ color: '#D1CFD6', id: 'entry2-2' },
					{ color: '#D1CFD6', id: 'entry2-3' },
					{ color: '#D1CFD6', id: 'entry2-4' },
				],
			},
			{
				id: 'entry3',
				name: 'Dark Purple',
				color: '#474547',
				shades: [
					{ color: '#474547', id: 'entry3--4' },
					{ color: '#474547', id: 'entry3--3' },
					{ color: '#474547', id: 'entry3--2' },
					{ color: '#474547', id: 'entry3--1' },
					{ color: '#474547', id: 'entry3' },
					{ color: '#474547', id: 'entry3-1' },
					{ color: '#474547', id: 'entry3-2' },
					{ color: '#474547', id: 'entry3-3' },
					{ color: '#474547', id: 'entry3-4' },
				],
			},
		],

		themes: [
			{
				id: 'theme0',
				name: 'Light',
				text: {
					primary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryalt: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					secondary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					tertiary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accent: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentalt: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					success: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					error: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warning: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					oncolor: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentaltplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					successplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					errorplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warningplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentaltminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					successminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					errorminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warningminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
				},
				bg: {
					primary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryalt: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					secondary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					tertiary: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accent: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentalt: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					success: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					error: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warning: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryaltplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					secondaryplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					tertiaryplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentaltplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					successplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					errorplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warningplus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					primaryaltminus: {
						entryId: null,
						shadeId: null,
						snapshot: '#FF0000',
					},
					secondaryminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					tertiaryminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					accentaltminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					successminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					errorminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
					warningminus: { entryId: null, shadeId: null, snapshot: '#FF0000' },
				},
			},
		],
	}: TSpecsColor),

	widgetspec1: {
		_id: 'widgetspec1',
		kind: 'widgetspec',
		name: 'The Foonts',

		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: _siteId,
		userId: _userId,

		text: [],
		button: [],
		icon: [],
		block: [],
		col: [],
		image: [],
		stack: [],
	},
};
