// @flow

import type { TWidgets } from '@graphite/types';
import { presetsDesign } from './custom-design-presets';

export const presetWidgets: TWidgets = {
	project__REPLACE_WITH_NEW_ID: {
		_id: 'project__REPLACE_WITH_NEW_ID',
		kind: 'project',
		name: 'Project',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		children: {},
		syncedAt: null,
		updatedAt: null,
	},
	site__REPLACE_WITH_NEW_ID: {
		_id: 'site__REPLACE_WITH_NEW_ID',
		kind: 'site',
		name: 'Blank',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'market',
		scopeId: null,
		children: { page_main__REPLACE_WITH_NEW_ID: 'page_main__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
		gridspecId: 'gridspecId_PRESET',
		widgetspecId: 'widgetspecId_PRESET',
		colorspecId: 'colorspecBootstrapId_PRESET',
		effectspecId: 'effectspecId_PRESET',
	},
	page__REPLACE_WITH_NEW_ID: {
		_id: 'page__REPLACE_WITH_NEW_ID',
		kind: 'page',
		name: 'Page',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		children: { block__REPLACE_WITH_NEW_ID: 'block__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
	},
	page_main__REPLACE_WITH_NEW_ID: {
		_id: 'page_main__REPLACE_WITH_NEW_ID',
		kind: 'page',
		name: 'Page',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		url: 'index',
		children: { block__REPLACE_WITH_NEW_ID: 'block__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
	},
	block__REPLACE_WITH_NEW_ID: {
		_id: 'block__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'block',
		name: 'Section',
		display: 'preset',
		scope: 'system',
		scopeId: null,
		removedAt: null,
		protoId: null,
		box: {
			desktop: {
				padding: {
					bottom: 180,
					top: 180,
				},
			},
		},
		designId: 'custom_block',
		designs: {
			custom_block: presetsDesign.custom_block,
		},
	},
	col__REPLACE_WITH_NEW_ID: {
		_id: 'col__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'col',
		name: 'Column',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		box: {
			desktop: {
				alignContent: 'flex-start',
				alignItems: 'stretch',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},
		},
	},
	text__REPLACE_WITH_NEW_ID: {
		_id: 'text__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'text',
		name: 'Text',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		designs: {
			custom_text: presetsDesign.custom_text,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 300,
				},
			},
		},
		raw: {
			blocks: [
				{
					data: {
						align: 'left',
					},
					depth: 0,
					entityRanges: [],
					inlineStyleRanges: [],
					key: 'e314r',
					text: 'Lorem ipsum dolor sit amet, ea elit eruditi sit.',
					type: 'custom_text-div',
				},
			],
			entityMap: {},
		},
	},
	image__REPLACE_WITH_NEW_ID: {
		_id: 'image__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'image',
		name: 'Image',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		alt: '',
		cropMode: 'fill',
		designId: 'custom_image',
		designs: {
			custom_image: presetsDesign.custom_image,
		},
		box: {
			desktop: {
				height: 400,
				heightUnit: 'px',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					height: 200,
					width: 200,
				},
				objectPositionX: 50,
				objectPositionXUnit: '%',
				objectPositionY: 50,
				objectPositionYUnit: '%',
			},
		},
		src:
			'https://images.unsplash.com/photo-1505322715123-90e9aa1e2d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&q=80',
	},
	rectangle__REPLACE_WITH_NEW_ID: {
		_id: 'rectangle__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'rectangle',
		name: 'Rectangle',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		designId: 'custom_rectangle',
		designs: {
			custom_rectangle: presetsDesign.custom_rectangle,
		},
		box: {
			desktop: {
				height: 120,
				width: 120,
				heightUnit: 'px',
				widthUnit: 'px',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 120,
					height: 120,
				},
			},
		},
	},
	button__REPLACE_WITH_NEW_ID: {
		_id: 'button__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'button',
		name: 'Button',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_button',
		designs: {
			custom_button: presetsDesign.custom_button,
		},
		icon: {
			isShown: false,
			name: 'faStar',
			position: 'left',
		},
		box: {
			desktop: {
				alignSelf: 'flex-start',
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		text: { isShown: true, label: 'Button' },
	},
	button_form__REPLACE_WITH_NEW_ID: {
		_id: 'button_form__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'button',
		name: 'Button',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_button',
		designs: {
			custom_button: presetsDesign.custom_button,
		},
		icon: {
			isShown: false,
			name: 'faStar',
			position: 'left',
		},
		box: {
			desktop: {
				alignSelf: 'flex-end',
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		text: { isShown: true, label: 'Send' },
	},
	stack__REPLACE_WITH_NEW_ID: {
		_id: 'stack__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'stack',
		name: 'Container',
		display: 'preset',
		scope: 'system',
		protoId: null,
		scopeId: null,
		removedAt: null,
		designId: 'custom_stack',
		designs: {
			custom_stack: presetsDesign.custom_stack,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				padding: { top: 30, left: 30, right: 30, bottom: 30 },
				offset: {
					width: 300,
				},
				alignContent: 'flex-start',
				alignItems: 'flex-start',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},
		},
	},
	spacer__REPLACE_WITH_NEW_ID: {
		_id: 'spacer__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'spacer',
		name: 'Spacer',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		box: {
			desktop: {
				height: 50,
				heightUnit: 'px',
				widthUnit: 'auto',
				offset: {
					width: 150,
					height: 50,
				},
			},
		},
	},
	stackform__REPLACE_WITH_NEW_ID: {
		_id: 'stackform__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'stack',
		name: 'Form',
		display: 'preset',
		scope: 'system',
		protoId: null,
		positions: {},
		children: {
			input_form_name__REPLACE_WITH_NEW_ID: 'input_form_name__REPLACE_WITH_NEW_ID',
			input_form_email__REPLACE_WITH_NEW_ID:
				'input_form_email__REPLACE_WITH_NEW_ID',
			textarea_form__REPLACE_WITH_NEW_ID: 'textarea_form__REPLACE_WITH_NEW_ID',
			button_form__REPLACE_WITH_NEW_ID: 'button_form__REPLACE_WITH_NEW_ID',
		},
		scopeId: null,
		removedAt: null,
		tag: 'form',
		designId: 'custom_stackform',
		title: 'Contact form',
		designs: {
			custom_stackform: presetsDesign.custom_stackform,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 300,
				},
				alignContent: 'stretch',
				alignItems: 'stretch',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},
		},
	},
	input__REPLACE_WITH_NEW_ID: {
		_id: 'input__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'input',
		name: 'Input',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_input',
		designs: {
			custom_input: presetsDesign.custom_input,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		input: {
			label: 'Input',
			isRequired: false,
			maxlength: null,
			placeholder: 'Input',
			type: 'text',
		},
	},
	input_form_email__REPLACE_WITH_NEW_ID: {
		_id: 'input_form_email__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'input',
		name: 'Input',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_input',
		designs: {
			custom_input: presetsDesign.custom_input,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		input: {
			label: 'email',
			isRequired: false,
			maxlength: null,
			placeholder: 'Email',
			type: 'text',
		},
	},
	input_form_name__REPLACE_WITH_NEW_ID: {
		_id: 'input_form_name__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'input',
		name: 'Input',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_input',
		designs: {
			custom_input: presetsDesign.custom_input,
		},
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		input: {
			label: 'name',
			isRequired: false,
			maxlength: null,
			placeholder: 'Name',
			type: 'text',
		},
	},
	textarea__REPLACE_WITH_NEW_ID: {
		_id: 'textarea__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'textarea',
		name: 'Textarea',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_textarea',
		designs: {
			custom_textarea: presetsDesign.custom_textarea,
		},
		box: {
			desktop: {
				height: 150,
				heightUnit: 'px',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		textarea: {
			label: 'Textarea',
			isRequired: false,
			maxlength: null,
			placeholder: 'Textarea',
			resize: 'both',
		},
	},
	textarea_form__REPLACE_WITH_NEW_ID: {
		_id: 'textarea_form__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'textarea',
		name: 'Textarea',
		display: 'normal',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'custom_textarea',
		designs: {
			custom_textarea: presetsDesign.custom_textarea,
		},
		box: {
			desktop: {
				height: 150,
				heightUnit: 'px',
				width: 100,
				widthUnit: '%',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		textarea: {
			label: 'message',
			isRequired: false,
			maxlength: null,
			placeholder: 'Message',
			resize: 'both',
		},
	},
	captcha__REPLACE_WITH_NEW_ID: {
		_id: 'captcha__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'captcha',
		name: 'Captcha',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		box: {
			desktop: {
				height: 76,
				heightUnit: 'px',
				widthUnit: 'auto',
				offset: {
					width: 150,
				},
			},
		},
	},
	code__REPLACE_WITH_NEW_ID: {
		_id: 'code__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'code',
		name: 'Code',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		code: `<style>
  .box {
      background: #e9ecef;
      padding: 20px;
      font: 14px/20px monospace;
    }
</style>

<div class="box">Hello World!</div>`,
		box: {
			desktop: {
				heightUnit: 'auto',
				widthUnit: 'auto',
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
	},
};

export default {};
