// @flow
import React from 'react';
import _ from 'lodash/fp';
import type { TSx } from '@graphite/types';

import { Flex } from '../Box';
import Text from '../Text';

type TProps = $ReadOnly<{|
	value?: ?string,
	placeholder?: ?string,
	max?: ?number,
	prefix?: ?string,
	suffix?: ?string,
	prefixGap?: ?number,
	suffixGap?: ?number,
	isDisabled?: ?boolean,
	isGray?: ?boolean,
	sx?: ?TSx,
	onChange?: ?(string) => void,
	onEnter?: ?(string) => void,
	onBlur?: ?(string) => void,
	onKeyDown?: ?(KeyboardEvent) => void,
	onValidate?: ?(string) => boolean | void,
	onFilter?: string => string,
	maxWidth?: string,
|}>;

const PADDING_INPUT_BASE = 7;

const containerStyle = {
	cursor: 'text',
	alignItems: 'center',
	position: 'relative',

	height: '30px',
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: `${PADDING_INPUT_BASE}px`,
	paddingRight: `${PADDING_INPUT_BASE}px`,

	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	backgroundColor: 'transparent',
	borderRadius: 'md.all',
	transition: 'background-color, border 0.15s ease-in',

	':hover': {
		backgroundColor: 'bg.primaryalt',
	},
	':focus-within': {
		backgroundColor: 'transparent',
		borderColor: 'bg.accent',
	},
};

const containerDisabledStyle = {
	...containerStyle,
	cursor: 'default',
	padding: '0',
	margin: '0',
	borderWidth: '0',
	':hover': {
		backgroundColor: 'transparent',
	},
};

const valueTextStyle = {
	position: 'absolute',
	width: '100%',
	left: 0,

	height: '30px',
	paddingTop: 0,
	paddingBottom: 0,

	borderWidth: '0',
	backgroundColor: 'transparent',
	outline: 'none',

	'::placeholder': {
		color: 'text.tertiary',
		opacity: 1 /* Firefox */,
	},
};

const watcherTextStyle = {
	opacity: 0,
	userSelect: 'none',
	pointerEvents: 'none',
};

const prefixStyle = {
	marginRight: '2px',
};

const suffixStyle = {
	marginLeft: '6px',
};

const limitedValue = (value: ?string, max: ?number, onFilter: ?(string) => string) => {
	let editValue = value || '';

	if (onFilter) editValue = onFilter(editValue);
	if (max) editValue = editValue.slice(0, max);

	return editValue;
};

function InputInplace({
	value = '',
	max = 0,
	maxWidth,
	placeholder = '',
	prefix = '',
	prefixGap = 0,
	suffix = '',
	suffixGap = 0,
	isDisabled = false,
	isGray = false,
	sx = null,
	onChange = null,
	onValidate = null,
	onKeyDown = null,
	onEnter = null,
	onBlur = null,
	onFilter,
}: TProps) {
	const [ownValue, setOwnValue] = React.useState<string>(
		limitedValue(value, max, onFilter),
	);
	const [error, setError] = React.useState<boolean>(
		(onValidate && onValidate(ownValue) === false) || false,
	);

	React.useEffect(() => {
		const limited = limitedValue(value, max, onFilter);
		setOwnValue(limited);
		setError((onValidate && onValidate(limited) === false) || false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, onFilter]);

	const watcherRef = React.useRef();
	const inputRef = React.useRef();
	const focus = React.useRef();

	const grab = React.useCallback(() => {
		if (inputRef.current && !focus.current) {
			inputRef.current.select();
			focus.current = true;
		}
	}, [focus]);

	const changeValue = React.useCallback(
		({ target }) => {
			const sliced = limitedValue(target.value, max, onFilter);

			if (onValidate && onValidate(sliced) === false) {
				setOwnValue(sliced);
				setError(true);
				return;
			}
			setError(false);
			setOwnValue(sliced);

			if (onChange) {
				onChange(sliced);
			}
		},
		[max, onValidate, onChange, onFilter],
	);

	const handleKeyDown = React.useCallback(
		e => {
			if (onKeyDown) {
				onKeyDown(e);
			}

			if (e.key === 'Enter') {
				if (onEnter) {
					const sliced = limitedValue(e.target.value, max, onFilter);
					if (onValidate && onValidate(sliced)) onEnter(sliced);
				}
				if (inputRef.current) {
					inputRef.current.blur();
					focus.current = false;
				}
			}
		},
		[max, onEnter, onKeyDown, onValidate, onFilter],
	);

	const handleBlur = React.useCallback(
		e => {
			if (onBlur) {
				const sliced = limitedValue(e.target.value, max, onFilter);
				if (onValidate && onValidate(sliced)) onBlur(sliced);
			}

			focus.current = false;
		},
		[max, onBlur, onValidate, onFilter],
	);

	const containerBoxStyle = React.useMemo(() => {
		const baseSx = (isDisabled && containerDisabledStyle) || containerStyle;
		if (!sx) {
			return baseSx;
		}
		return _.assign(baseSx, sx);
	}, [isDisabled, sx]);

	const color =
		(isDisabled && 'text.tertiary') ||
		(error && 'text.error') ||
		(isGray && 'text.tertiary') ||
		'text.primary';

	const inputSx = React.useMemo(
		() => ({
			...valueTextStyle,
			paddingLeft: `${PADDING_INPUT_BASE + prefixGap}px`,
			paddingRight: `${PADDING_INPUT_BASE + suffixGap}px`,
			maxWidth: maxWidth
				? `calc(${maxWidth} + ${(PADDING_INPUT_BASE + prefixGap + suffixGap) *
						2}px)`
				: '100%',
		}),
		[prefixGap, suffixGap, maxWidth],
	);

	const valueSx = React.useMemo(
		() => ({
			...watcherTextStyle,
			maxWidth,
		}),
		[maxWidth],
	);

	return (
		<Flex onClick={grab} sx={containerBoxStyle}>
			{prefix && (
				<Text variant="headlinesm" color={color} sx={prefixStyle}>
					{prefix}
				</Text>
			)}
			<Text
				as="span"
				variant="headlinesm"
				color={color}
				ref={watcherRef}
				sx={valueSx}
			>
				{ownValue || placeholder}
			</Text>
			{suffix && (
				<Text as="span" variant="headlinesm" color={color} sx={suffixStyle}>
					{suffix}
				</Text>
			)}
			<Text
				as="input"
				variant="headlinesm"
				color={color}
				sx={inputSx}
				ref={inputRef}
				type="text"
				maxLength={max || 999}
				placeholder={placeholder}
				value={isDisabled ? value : ownValue}
				onChange={changeValue}
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				disabled={isDisabled}
			/>
		</Flex>
	);
}

export default React.memo<TProps>(InputInplace);
