// @flow

import React, { memo, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash/fp';
import { Box } from 'rebass';
import { zIndices } from '@graphite/constants';
import type { TSx } from '@graphite/types';
import Icon from '../Icon';
import Popper from '../Popper';

// TopBar 54, 12 offset
const DEFAULT_MAX_HEIGHT = 'calc(100vh - 78px)';
const DEFAULT_WIDTH = 318;
const DEFAULT_PADDING_X = 24;

type TFakeMouseEvent = (MouseEvent) => void;

type TProps = $ReadOnly<{|
	children: React$Node,
	width?: number | 'auto',
	opacity?: ?number,
	isOpen: boolean,
	noDrag?: boolean,
	zIndex?: number,
	offsetTop?: number,
	offsetLeft?: number,
	maxHeight?: ?string,
	height?: string,
	mutex?: ?string,
	anchorEl: {| current: ?React$ElementRef<any> |},
	onClose?: TFakeMouseEvent,
	isFixed?: boolean,
	className?: string,
	sx?: ?TSx,
	placement?: 'top' | 'bottom' | 'right' | 'left',
|}>;

const containerSx = {
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 'lg.all',
	userSelect: 'none',
	boxShadow: 'lg',
	paddingTop: '24px',
	paddingRight: '0',
	paddingBottom: '12px',
	backgroundColor: 'bg.primary',
	scrollSnapType: 'both mandatory',
};

const scrollContainerStyle = {
	// set correct position for scroll container to prevent content jumping
	marginLeft: `${DEFAULT_PADDING_X}px`,
	marginRight: `${DEFAULT_PADDING_X}px`,
};

const scrollSx = {
	flexGrow: 1,
	overflowY: 'auto',
	overflowX: 'hidden',

	'::-webkit-scrollbar': {
		width: '6px',
		backgroundColor: 'transparent',
	},
	'::-webkit-scrollbar-button': {
		display: 'none',
	},
	'::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	'::-webkit-scrollbar-track:hover': {
		backgroundColor: 'bg.primaryaltplus',
	},
	'::-webkit-scrollbar-thumb': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	':hover::-webkit-scrollbar-thumb': {
		backgroundColor: 'bg.secondaryminus',
	},
	'::-webkit-scrollbar-thumb:hover': {
		backgroundColor: 'text.tertiary',
	},
};

const topHandleStyle = {
	position: 'absolute',
	top: '0',
	right: '0',
	left: '0',
	height: '24px',
	textAlign: 'center',
	cursor: 'grab',
	zIndex: 1,
};

const CrossStyled = styled(Box)`
	position: absolute;
	top: 25px;
	right: 18px;
	z-index: 1;
	background: ${({ theme }) => theme.colors.bg.primary};
	box-shadow: 0 0 5px 5px ${({ theme }) => theme.colors.bg.primary};
`;

type TMutexEntry = $ReadOnly<{|
	ownId: string,
	isOpen: boolean,
	onClose?: TFakeMouseEvent,
|}>;

type TMutexGroup = { [string]: TMutexEntry };
type TMutexStorage = { [string]: TMutexGroup };

const mutexStorage: TMutexStorage = {};

const PopupWhite = (
	{
		children,
		width = DEFAULT_WIDTH,
		onClose = null,
		opacity = null,
		zIndex = zIndices.popup,
		offsetTop = 0,
		offsetLeft = 0,
		isOpen,
		anchorEl,
		isFixed = true,
		noDrag = false,
		maxHeight = DEFAULT_MAX_HEIGHT,
		height,
		mutex = null,
		className,
		sx = null,
		placement = 'bottom',
	}: TProps,
	ref,
) => {
	const ownId = useMemo<string>(() => `${Math.random()}`.slice(2), []);
	const [dragSelector] = useState<?string>(noDrag ? null : `.js-popup-drag${ownId}`);
	const [ownMutex] = useState<?string>(mutex);

	// Этот эффект регистрирует мьютекс при маунте и удаляет его при анмаунте
	// Если этот попап открыт, то все другие на этом мьютексе закрываются
	useEffect(() => {
		if (!ownMutex) {
			return;
		}
		if (!mutexStorage[ownMutex]) {
			mutexStorage[ownMutex] = ({}: TMutexGroup);
		}
		const group: TMutexGroup = mutexStorage[ownMutex];
		group[ownId] = { ownId, isOpen, onClose };

		// Закрыть все остальные попапы
		if (isOpen) {
			_.forEach((entry) => {
				if (
					entry &&
					typeof entry === 'object' &&
					entry.ownId &&
					entry.ownId !== ownId &&
					entry.isOpen &&
					entry.onClose &&
					typeof entry.onClose === 'function'
				) {
					const fakeE = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: true,
						clientX: 20,
					});
					entry.onClose(fakeE);
				}
			}, group);
		}

		// eslint-disable-next-line consistent-return
		return () => {
			if (!ownMutex) {
				return;
			}
			// eslint-disable-next-line no-shadow
			const group: TMutexGroup = mutexStorage[ownMutex];
			delete group[ownId];
		};
	}, [isOpen, onClose, ownId, ownMutex]);

	// prevent content jumping when scroll appears
	const scrollContainerWidth =
		typeof width === 'number' ? `${width - DEFAULT_PADDING_X * 2}px` : width;

	const heightStyle = useMemo(
		() => ({ maxHeight, height: height === 'full' ? maxHeight : height }),
		[maxHeight, height],
	);
	const widthStyle = useMemo(
		() => ({ width: scrollContainerWidth }),
		[scrollContainerWidth],
	);

	const containerThemedSx = useMemo(
		() => ({
			...containerSx,
			...sx,
		}),
		[sx],
	);

	return (
		<Popper
			isOpen={isOpen}
			anchorEl={anchorEl}
			zIndex={zIndex}
			opacity={opacity}
			offsetTop={offsetTop}
			offsetLeft={offsetLeft}
			dragSelector={dragSelector}
			isFixed={isFixed}
			className={className}
			placement={placement}
		>
			<Box width={width} sx={containerThemedSx} style={heightStyle}>
				{dragSelector && (
					<Box className={dragSelector.slice(1)} sx={topHandleStyle}>
						<Icon
							name="dots-horizontal-thin-6"
							iconSize={18}
							size="xxsm"
							colors="tertiaryflat"
						/>
					</Box>
				)}

				{onClose && (
					<CrossStyled data-kind="close-popup">
						<Icon
							name="cross"
							iconSize={18}
							size="sm"
							colors="tertiaryflat"
							onClick={onClose}
						/>
					</CrossStyled>
				)}
				<Box sx={scrollSx} ref={ref}>
					<Box sx={scrollContainerStyle} style={widthStyle}>
						{children}
					</Box>
				</Box>
			</Box>
		</Popper>
	);
};

export default memo<TProps>(React.forwardRef(PopupWhite));
