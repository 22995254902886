// @flow
import React from 'react';
import { type TSx } from '@graphite/types';
import Box from '../Box';
import SelectSimple from '../SelectSimple';
import { type TProps as TPropsSelect } from '../SelectSimple/SelectSimple';

const boxSx = {
	padding: '0 6px',
};

const defaultSx = {
	width: '144px',
};

const buttonSx: ?TSx = {
	justifyContent: 'space-between',
};

const ToolbarSelect = (props: TPropsSelect, ref) => (
	<Box data-kind="toolbar-select" sx={boxSx} ref={ref}>
		{/* eslint-disable-next-line react/jsx-props-no-spreading */}
		<SelectSimple size="sm" sx={defaultSx} buttonSx={buttonSx} {...props} />
	</Box>
);

export default React.memo<TPropsSelect>(React.forwardRef(ToolbarSelect));
